import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/page/layout"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/page/home"),
      },
      {
        path: "/",
        name: "home",
        component: () => import("@/page/home"),
      },
      {
        path: "/",
        name: "home",
        component: () => import("@/page/home"),
      },
      {
        path: "/platform",
        name: "platform",
        component: () => import("@/page/platform"),
      },
      {
        path: "/platform/yuansheng",
        name: "yuansheng",
        component: () => import("@/page/platform/yuansheng"),
      },
      {
        path: "/platform/serve",
        name: "platformServe",
        component: () => import("@/page/platform/serve"),
      },
      {
        path: "/platform/code",
        name: "platformCode",
        component: () => import("@/page/platform/code"),
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("@/page/activity"),
      },
      {
        path: "/activity/courseInfo",
        name: "courseInfo",
        component: () => import("@/page/activity/courseInfo"),
      },
      {
        path: "/activity/activityInfo",
        name: "activityInfo",
        component: () => import("@/page/activity/activityInfo"),
      },
      {
        path: "/searchInfo",
        name: "searchInfo",
        component: () => import("@/page/searchInfo"),
      },
      {
        path: "/replace",
        name: "replace",
        component: () => import("@/page/replace"),
      },
      {
        path: "/replace/info",
        name: "replaceInfo",
        component: () => import("@/page/replace/info"),
      },
      {
        path: "/replace/productInfo/:type",
        name: "productInfo",
        component: () => import("@/page/replace/productInfo"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/page/about"),
      },
      {
        path: "/policy",
        name: "policy",
        component: () => import("@/page/policy"),
      },
      {
        path: "/policy/info",
        name: "policyInfo",
        component: () => import("@/page/policy/info"),
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/page/solution"),
      },
      {
        path: "/solution/info",
        name: "solutionInfo",
        component: () => import("@/page/solution/info"),
      },
      {
        path: "/solution/ordinary_program",
        name: "ordinary_program",
        component: () => import("@/page/solution/ordinary_program"),
      },
      {
        path: "/solution/internet_things",
        name: "internet_things",
        component: () => import("@/page/solution/internet_things"),
      },
      {
        path: "/solution/digitizing",
        name: "digitizing",
        component: () => import("@/page/solution/digitizing"),
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/page/register"),
  },
  {
    path: "/protocol",
    name: "protocol",
    component: () => import("@/page/protocol"),
  },
  {
    path: "/protocol/serve",
    name: "protocolServe",
    component: () => import("@/page/protocol/serve"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/page/forgetPassword"),
  },
  {
    path: "/newPassword",
    name: "newPassword",
    component: () => import("@/page/forgetPassword/newPassword"),
  },
  {
    path: "/emailCode",
    name: "emailCode",
    component: () => import("@/page/forgetPassword/email"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
