<template>
  <div class="serve-enterprise">
    <strong>企业侧-赋能企业</strong>
    <p>金牌服务商</p>
    <div class="serve-enterprise-jp">
      <div class="serve-enterprise-jp-img">
        <div class="serve-enterprise-jp-img">
          <img :class="{'active':serveIndex == 1}" src="@/assets/img/skhbg.png" alt="服务商" />
          <img :class="{'active':serveIndex == 2}" src="@/assets/img/sbbg.png" alt="服务商" />
          <img :class="{'active':serveIndex == 3}" src="@/assets/img/dqbg.png" alt="服务商" />
        </div>
        <div class="serve-enterprise-jp-logo">
          <span :class="{'active':serveIndex == 1}" @click="serveIndex = 1"><img src="@/assets/img/01-1@2x.png" alt="服务商" /></span>
          <span :class="{'active':serveIndex == 2}" @click="serveIndex = 2"><img src="@/assets/img/02-1@2x.png" alt="服务商" /></span>
          <span :class="{'active':serveIndex == 3}" @click="serveIndex = 3"><img src="@/assets/img/03-1@2x.png" alt="服务商" /></span>
        </div>
      </div>
      <div class="serve-enterprise-desc">
        <div :class="{'active':serveIndex == 1}">
          <span>深圳开鸿数字产业发展有限公司</span>
          <p>
            深圳开鸿数字产业发展有限公司（深开鸿），专注于物联网操作系统的高科技公司，注册资金3亿元，注册地位于深圳福田区。深开鸿见证了全世界第一个面向万物互联的全场景操作系统鸿蒙的诞生和成长，是鸿蒙生态伟大里程碑的共建者。深开鸿专注于泛智能终端操作系统技术和产品，致力于推动消费物联、产业物联的高科技公司，与华为紧密配合，面向消费类市场推广华为Harmony
            OS、面向行业市场研发并推广鸿蒙发行版OS，构建华为Harmony
            OS、鸿蒙发行版OS有益补充的鸿蒙生态，作为鸿蒙生态全栈式使能伙伴，助力并加速智能物联网领域的产品化与技术创新。
          </p>
        </div>
        <div :class="{'active':serveIndex == 2}">
          <span>三宝科技股份有限公司</span>
          <p>
            三宝科技（HK01708），秉承“智慧创造品质生活”的使命，以“连接、开放、共享”的价值理念，致力于构建新型IoT科技供应链服务版图，依靠“互联网+物联网”技术，深耕“智慧交通”和“智慧物流”两大产业生态圈，共建智慧城市。以行业性、区域性的资源主导者为合作对象，以打造开放的合作平台集聚业务资源，为用户提供基于大数据的系统集成服务、云监管服务、海关通关服务及配套金融服务，打通供应链数据互通渠道，协同并聚力生态企业共同为中小企业提供高品质的综合服务，共建工业互联网平台。
          </p>
        </div>
        <div :class="{'active':serveIndex == 3}">
          <span>河南驰诚电气股份有限公司</span>
          <p>
            河南驰诚电气股份有限公司，成立于2004年，注册资本5418万元，位于郑州市国家高新技术产业开发区，股票代码为834407，是高新技术企业、省科技型中小企业、省工业企业品牌培育试点企业、省电子商务认定企业、市工程技术研究中心、市百高企业，拥有一支严谨、精干、创新的研发和服务团队。公司自成立十多年来专业从事高、精、尖仪器仪表的研发、生产与销售，具有自营进出口资格，并通过了国际质量管理体系ISO9001：2008认证、环境管理体系ISO14001：2004认证、职业健康安全管理体系OHSAS18001：2007认证、欧盟CE安全认证。公司已累计获得有效授权专利24项（ZL 2011 2 0430684.0等），其中发明专利2项，软件著作权26项（许昌驰诚6项），消防产品认证13项（替代3C证书），防爆合格证20项，全国工业产品生产许可证1项，国家制造计量器具型式批准证21项（替代许可证），欧盟CE安全认证5项。
          </p>
        </div>
        <img src="@/assets/img/yh.png" alt="yh" />
      </div>
    </div>
    <p>优质服务商</p>
    <div class="serve-enterprise-yz">
      <swiper
        ref="swiperThumbs"
        class="swiper subswiper-box"
        :options="swiperOptionThumbs"
      >
        <swiper-slide
          v-for="(el, index) in enterpriseList"
          :key="index"
          class="enterprise-swiper-list"
        >
          <div class="enterprise-swiper-tab-info">
            <img :src="el.logo" alt="logo" />
            <p>{{ el.name }}</p>
            <span>专注领域：{{ el.type }}</span>
            <div>
              <a-rate v-model="el.rate" disabled allow-half />
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div
        class="swiper-next"
        :style="{ opacity: enterpriseList.length > 4 ? '1' : '0.4' }"
      >
        <a-icon type="right" />
      </div>
      <div
        class="swiper-prev"
        :style="{ opacity: enterpriseList.length > 4 ? '1' : '0.4' }"
      >
        <a-icon type="left" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiperOptionThumbs() {
      return {
        spaceBetween: 24,
        slidesPerView: 4,
        loop:true,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      };
    },
  },
  data() {
    return {
      serveIndex:1,
      enterpriseList: [
        {
          logo: require("@/assets/img/allogo.png"),
          name: "苏州奥莱维信息技术有限公司",
          type: "智能家居",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/jhlogo.png"),
          name: "江苏吉华电子科技有限公司",
          type: "电子产品智能制造",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/gllogo.png"),
          name: "河南格朗电子科技有限公司",
          type: "智能充电设备",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/fllogo.png"),
          name: "嘉兴泛联通信技术有限公司",
          type: "物联网无线通信",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/afglogo.png"),
          name: "苏州欧菲光科技有限公司",
          type: "智能汽车",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/bslogo.png"),
          name: "江苏博顺信息科技有限公司",
          type: "数字乡村",
          rate: 4.5,
        },
        {
          logo: require("@/assets/img/zkglogo.png"),
          name: "盐城中科高通量计算研究院有限公司",
          type: "人工智能",
          rate: 4.5,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.serve-enterprise {
  margin-top: 48px;
  padding-bottom: 48px;
  > strong {
    width: 1264px;
    margin: 0 auto 32px;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    line-height: 40px;
    display: block;
  }
  > p {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    font-weight: 600;
    width: 1264px;
    margin: 0 auto 32px;
  }
  .serve-enterprise-jp {
    display: flex;
    margin-bottom: 48px;
    > div {
      flex: 1;
    }
    .serve-enterprise-jp-img {
      background: #fff;
      .serve-enterprise-jp-img {
        height: 284px;
        > img {
          display: none;
          width: 100%;
          height: 100%;
          &.active {
            display: block;
          }
        }
      }
      .serve-enterprise-jp-logo {
        display: flex;
        justify-content: flex-end;
        > span {
          width: 210px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
          cursor: pointer;
          border-left: 1px solid rgba(0, 0, 0, 0.09);

          &:first-child {
            border-left: 0;
          }
          > img {
            -webkit-filter: grayscale(100%); 
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            -o-filter: grayscale(100%);
            max-width: 100%;
            max-height: 100%;
            opacity: .65;
          }
          &.active{
            >img{
              -webkit-filter: grayscale(0%); 
              -moz-filter: grayscale(0%);
              -ms-filter: grayscale(0%);
              -o-filter: grayscale(0%);
              opacity: 1;
            }
          }
        }
      }
    }
    .serve-enterprise-desc {
      padding: 48px;
      background: #666;
      position: relative;
      > div {
        display: none;
        width: 584px;
        > span {
          display: block;
          font-size: 20px;
          color: #ffffff;
          font-weight: 600;
          line-height: 28px;
          margin-bottom: 24px;
        }
        > p {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.85);
          line-height: 24px;
          text-align: justify;
        }
        &.active {
          display: block;
        }
      }
      > img {
        position: absolute;
        display: block;
        width: 48px;
        height: 40px;
        bottom: 56px;
        z-index: 2;
        left: 590px;
      }
    }
  }
  .serve-enterprise-yz {
    width: 1264px;
    margin: 0 auto;
    position: relative;
    .swiper-next,
    .swiper-prev {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      width: 32px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0,0,0,0.25);
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
    .swiper-prev {
      left: -56px;
    }
    .swiper-next {
      right: -56px;
    }
    .enterprise-swiper-tab-info {
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      padding: 48px 20px;
      > img {
        height: 80px;
        max-width: 100%;
        display: block;
        margin: 0 auto 32px;
      }
      > p {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        line-height: 22px;
        margin-bottom: 16px;
      }
      > span {
        display: block;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 20px;
        margin-bottom: 32px;
      }
      > div {
        text-align: center;
      }
    }
  }
}
</style>
<style>
.serve-enterprise-yz .swiper-next.swiper-button-disabled{
  cursor: no-drop !important;
  opacity: 0.65 !important;
}
</style>