<template>
<div class="digitizing">
  <things-head :val="head_Content"></things-head>
  <div class="navigation">
    <a :class="{ active: solutionTabIndex === 'rol0' }" @click="setFloorNavMountClick('rol0')">方案介绍</a>
    <a :class="{ active: solutionTabIndex === 'rol1' }" @click="setFloorNavMountClick('rol1')">背景和价值</a>
    <a v-if="anabool" :class="{ active: solutionTabIndex === 'rol2' }" @click="setFloorNavMountClick('rol2')">行业分析</a>
    <a :class="{ active: solutionTabIndex === 'rol3' }" @click="setFloorNavMountClick('rol3')">产品架构</a>
    <a v-if="valbool" :class="{ active: solutionTabIndex === 'rol4' }" @click="setFloorNavMountClick('rol4')">功能清单</a>
  </div>
  <things-int :val="int_content" id="rol0"></things-int>

  <div id="rol1" class="digitizing-text box">
    <p>背景和价值</p>
    <div>{{backgroundValue}}</div>
  </div>

<!--  行业分析-->
  <digitizing-analyze id="rol2" v-if="anabool" :val="ana_content"></digitizing-analyze>

  <things-arc id="rol3" :val="arc_content"></things-arc>


  <div id="rol4" class="list box" v-if="valbool">

    <p>功能清单</p>
    <table class="list-table">
      <div class="table-right">
        <div>功能名称</div>
        <div v-for="item in fun_name" :key="item.id">
          {{item.name}}
        </div>
      </div>
      <div class="table-left">
        <div>功能点</div>
        <div v-for="item in fun_points" :key="item.id">
            <p>{{item.text}}</p>
        </div>
      </div>
    </table>
  </div>


  <sub-bottom></sub-bottom>


</div>
</template>

<script>
import {mapGetters} from "vuex";

import digitizing_analyze from "./components/digitizing_analyze";
import things_head from "@/page/solution/components/things_head";
import things_int from "@/page/solution/components/things_int";
import things_arc from "@/page/solution/components/things_arc";
import { getSolutionInfo } from "@/api/solution";
import sub_bottom from "@/page/solution/components/sub_bottom";

export default {
  name: "digitizing",
  components:{
    "digitizing-analyze":digitizing_analyze,
    "things-head":things_head,
    "things-int":things_int,
    "things-arc":things_arc,
    "sub-bottom":sub_bottom,
  },

  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },

  data(){
    return{
      solutionTabIndex: 'rol0',

      backgroundCon:{
        backgroundImage: 'url('+require("@/assets/img/solution/things6.png")+')',
      },

      head_Content:'',

      int_content:'',

      arc_content:'',

      backgroundValue:'',

      ana_content:[],

      fun_name:[],

      fun_points:[],

      isMask:false,

      anabool:false,  //行业分析

      valbool:false,  //功能清单


    }
  },

  mounted() {
    this.loadGetSolutionInfo();
  },

  methods:{



    loadGetSolutionInfo(){
      getSolutionInfo(this.id).then((res) =>{

        let createDate = new Date(res.createTime);
        let upDate = new Date('2022-07-21 00:00:00');
        createDate = createDate.getTime();
        upDate = upDate.getTime();
        if(createDate > upDate){
          this.isMask = true
        }


        this.head_Content={
          name:res.name,
          text:res.solSlogan,
          backgroundImg:res.solutionLogoImg,
          maskbool:this.isMask,
        }

        this.int_content={
          name:'方案介绍',
          text: res.solutionDiscription,
          img:res.solutionImg,
          backgroundImg:{
            backgroundImage: 'url('+require("@/assets/img/solution/things2.png")+')'
          }
        }

        this.arc_content={
          name:'产品架构',
          img:res.frameworkImg,
          backgroundImg:{
            backgroundImage: 'url('+require("@/assets/img/solution/digitizing4.png")+')'
          },
        }

        this.backgroundValue=res.backgroundValue

        //行业分析显示判断
        this.anabool=res.datSolutionIndustries.length>0


        let data=res.datSolutionIndustries


        for(let i=0;i<data.length;i+=2){

          this.ana_content.push(data.slice(i,i+2))
        }


        //功能清单显示判断
        this.valbool=res.datSolutionFunctions.length>0

        // 清单数据处理
        res.datSolutionFunctions.forEach(item=>{
          this.fun_name.push(
              {
                id:item.id,
                name:item.name
              }
          )
          let text=[]
          item.datSolutionFunctionpoints.forEach(_item=>{
            text.push(_item.name)
          })


          this.fun_points.push(
              {
                id:item.id,
                text:text.join().replace(/,/g,"、")
              }

          )
        })
      })
    },

    setFloorNavMountClick(id){
      this.solutionTabIndex=id
      if(id==='rol0'){
        document.getElementById(id).scrollIntoView(false);
      }else{
        document.getElementById(id).scrollIntoView({
          behavior:'smooth'
        });
      }
    }








  }
}
</script>

<style scoped lang="scss">
.box{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  >p{
    margin-top: 50px;
    color: #333333 ;
    font-size: 30px;
  }
}
.digitizing{
  .digitizing-text{
    margin: 0 auto;
    width: 1280px;
    //height: 386px;
    padding-top: 50px;
    padding-bottom: 100px;
    >div{
      font-size: 16px;
      font-weight: 500;
      color: #666666;
      margin-top: 50px;
    }
  }

  >.list{
    background: #FFFFFF;
    //padding-bottom: 50px;
    //height: 567px;
      >.list-table{
        width: 1280px;
        margin-top: 51px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        >.table-right{
          border: 1px solid #EEEEEE;
          box-shadow: 0px 0px 12px rgba(0,0,0,0.1000);
          >:nth-child(1){
            height: 90px;
            line-height: 90px;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            background: #F7F7F7;
          }
          >:nth-child(2){
            line-height: 118px;
          }
          >:nth-child(3){
            line-height: 94px;
          }
          >:nth-child(4){
            line-height: 71px;
          }
          >div{

            width: 322px;
            text-align: center;

          }
          >:not(:first-child){
            height: 118px;
            line-height: 118px;
            font-size: 16px;
            font-weight: 500;
            color: #666666;
          }
          >:not(:last-child){
            border-bottom: 1px solid #EEEEEE;
          }

        }
        >.table-left{
          width: 958px;
          border-bottom:1px solid #EEEEEE;
          border-right: 1px solid #EEEEEE;
          border-top: 1px solid #EEEEEE;
          >:nth-child(1){
            height: 90px;
            line-height: 90px;
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            background: #F7F7F7;
          }
          >:not(:first-child){
            height: 118px;
            font-size: 16px;
            font-weight: 500;
            color: #666666;
            padding-left: 24px;
            padding-right: 24px;
            display: flex;
            align-items: center;
          }
          >:not(:last-child){
            border-bottom: 1px solid #EEEEEE;
          }
        }
      }

  }
  >.navigation{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 71px;
    >a{
      //width: 72px;
      height: 60px;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin-right: 88px;
      line-height: 60px;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      &.active {
        color: #EA0B06;
        border-color: #EA0B06;
      }
    }
  }

}
</style>
