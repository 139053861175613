<template>
  <div class="home-replace">
    <banner-swiper
        :banner="banlist"
        :textinfo="textinfo"
    >
    </banner-swiper>
    <div class="home-replace-content-box">
      <div class="home-replace-content">
        <div class="home-replace-filter">
          <div>
            <span>设备类型：</span>
            <div style="height: 32px;">
              <span :class="{'active':productType == ''}" @click="productType = ''">全部</span>
              <span :class="{'active':productType == 'soc'}" @click="productType = 'soc'">SOC</span>
              <span :class="{'active':productType == 'dev'}" @click="productType = 'dev'">开发板</span>
              <span :class="{'active':productType == 'iot'}" @click="productType = 'iot'">IoT产品</span>
            </div>
          </div>
          <div>
            <span>所有分类：</span>
            <div style="height: 32px;">
              <span v-for="(item, index) in categoryList" :key="item.id" :class="{'active':replaceSortIndex == item.id}" @click="replaceSort(item.id,index)">{{item.name}}</span>
            </div>
          </div>
          <div>
            <span>二级分类：</span>
            <div :class="{ active: filterShow }">
              <span v-for="item in categorySubList" :key="item.id" :class="{'active':categorySubId == item.id}" @click="selectSubSort(item.pid,item.id)">{{item.name}}</span>
            </div>
            <p @click="filterShow = !filterShow">
              {{ filterShow ? "收起" : "展开"
              }}<a-icon :type="filterShow ? 'up' : 'down'" />
            </p>
          </div>
        </div>

        <!--    广告-->
        <div class="notif_img" v-if="advertise.notif" @click="toadvertise(advertise.notif.adHref)">
          <v-img
              class="img"
              v-if="advertise.notif"
              :attachment-id="advertise.notif.adAttmId"
              :default-img="require('@/assets/img/photo.png')"
          />
        </div>
        <div :class="{'loading-style':loading}">
          <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
            <p class="home-replace-list-num">共 {{page.total}} 个产品</p>
            <div class="home-replace-list" v-if="replaceList != ''">
              <div v-for="item in replaceList" :key="item.id" @click="replaceInfo(item.id,item.type)">
                <span><v-img :attachment-id="item.coverAttmId" :default-img="require('@/assets/img/kctp.png')" /></span>
                <div>
                  <p>{{item.name}}</p>
                  <div class="home-replace-list-lable" v-if="item.sceneTagName">
                    <template v-if="item.sceneTagName.indexOf(',') > 1">
                      <template v-for="(e,i) in item.sceneTagName.split(',')">
                        <span v-if="i < 3" :key="i">{{e}}</span>
                      </template>
                    </template>
                    <span v-else>{{item.sceneTagName}}</span>
                  </div>
                  <span v-html="item.discription"></span>
                </div>
              </div>
            </div>
            <div v-else style="padding:100px 0;display:block">
              <a-empty />
            </div>
          </a-skeleton>
        </div>
        <div class="home-replace-page">
          <pagination
            :total="page.total"
            :current="page.current"
            @changes="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getCategoryList } from "@/api/solution";
import { getIotProductList,getProductList } from "@/api/iot";
import pagination from "../components/pagination.vue";
import VImg from "@/components/VImg";
import {getAdvertiseList} from "@/api/advertise";
import BannerSwiper from "@/components/BannerSwiper";
export default {
  name:'replace',
  components: {
    pagination,
    VImg,
    'banner-swiper':BannerSwiper
  },
  data() {
    return {
      replaceSortIndex: '',
      replaceList: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      categoryList:[{
        id: "",
        name: "全部",
        pid: "",
      }],
      categorySubId:'',
      filterShow:false,
      categorySubList:[{
        id: "",
        name: "全部",
        pid: "",
      }],
      categoryData:[],
      categoryId:'',
      loading:false,
      banlist:[
        {
          img:require('@/assets/img/banner/replace.gif')
        }
      ],
      advertise:{
        banner:[],
        notif:'',
      },
      textinfo:{
        title:'国产替代',
        text:'细致、精准、智能的国产化适配方案',
        type:'replace',
        height:320,
      },
      productType:''
    };
  },
  computed: {
    ...mapGetters(["dict"]),
  },
  watch:{
    productType:function(val){
      this.page.current = 1
      this.loadGetIotProductList();
    }
  },
  created() {
    this.loadGetAdvertise();
  },
  mounted() {
    if(this.$route.query.item){
      if(this.$route.query.item==='1')  this.productType = 'soc'
      if(this.$route.query.item==='2')  this.productType = 'dev'
      if(this.$route.query.item==='3')  this.productType = 'iot'
    }
    if(this.$route.query.id){
      this.categorySubId = this.$route.query.id
      this.categoryId = this.$route.query.id
    }
    if(this.$route.query.idI){
      let item=this.$route.query.idI.charAt(this.$route.query.idI.length-1)
      let id=this.$route.query.idI.substring(0,this.$route.query.idI.length-1)

      this.categorySubId = id
      this.categoryId = id
      if(item==='3') this.productType = 'iot'
    }

    if(this.$route.params.lastId){
      this.replaceSortIndex = this.$route.params.lastId
      this.categorySubId = this.$route.query.id
      this.categoryId = this.$route.query.id
    }
    if(this.$route.params.id){
      this.replaceSortIndex = this.$route.params.id
      this.categorySubId = this.$route.params.subid
      this.categoryId = this.$route.params.subid
    }
    if(this.$route.params.type){
      this.productType = this.$route.params.type
    }
    this.LoadDictData(["PRODUCT_TYPE"]);
    this.LoadDictData(["CERT_TYPE"]);
    this.loadGetIotProductList();
    this.loadGetCategoryList();

  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetAdvertise(){
      let params={
        adType:'TECHNOLOGY_BANNER',
        validStatus:'1',
      }

      getAdvertiseList(params).then((res)=>{
        let data=res.records
        data.forEach(item=>{
          if(item.adType==="TECHNOLOGY_BANNER" && item.validStatus==1){
            this.advertise.banner.push(item)
          }else if(item.adType==='TECHNOLOGY_NOTIF_BANNER' && item.validStatus==1){
            this.advertise.notif=item
          }
        })
      })
    },
    toadvertise(url){
      if(url){
        window.open(url)
      }
    },
    // 列表
    loadGetIotProductList() {
      let { page,categoryId,replaceList,productType } = this;
      let data = {
        status: '2',
        categoryId,
        type:productType
      };
      if(replaceList == ''){
        this.loading = true
      }
      getProductList(page,data).then((res) => {
        this.replaceList = res.records
        this.page.total = res.total
        this.loading = false
      });
      // getIotProductList(page,data).then((res) => {
      //   this.replaceList = res.records
      //   this.page.total = res.total
      //   this.loading = false
      // });
    },
    // 类别列表
    loadGetCategoryList(){
      let id = this.$route.query.id
      getCategoryList('1').then((res) => {
        let data = res;
        this.categoryData = res;
        this.categoryList = [...this.categoryList,...data]
        data.forEach((element) => {
          element.children.forEach((item) => {
            this.categorySubList.push(item);
            if(id == item.id){
              this.replaceSortIndex = element.id
            }
          });
        });
      });
    },
    // 选择类别
    replaceSort(id,index) {
      this.categoryId = id
      this.replaceSortIndex = id;
      this.categorySubId = ''
      this.page.current = 1
      let { categoryList, categoryData } = this;
      this.categorySubList = [
        {
          id: "",
          name: "全部",
          pid: "0",
        },
      ];
      if (index != 0) {
        let data = categoryList[index].children;
        data.forEach((item) => {
          this.categorySubList.push(item);
        });
      } else {
        categoryData.forEach((element) => {
          element.children.forEach((item) => {
            this.categorySubList.push(item);
          });
        });
      }
      this.loadGetIotProductList();
    },
    selectSubSort(pid,id){
      this.categorySubId = id;
      this.replaceSortIndex = pid
      this.page.current = 1
      if(id != ''){
        this.categoryId = id
      }else{
        this.categoryId = ''
      }
      this.loadGetIotProductList();
    },
    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetIotProductList();
    },
    // 详情
    replaceInfo(id,type) {
      if(type === 'iot'){
        this.$router.push({
          path: "/replace/info",
          query: { id },
        });
      }else{
        this.$router.push({
          path: `/replace/productInfo/${type}`,
          query: { id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-replace {


   .notif_img{
    display: flex;
    justify-content: center;
    margin-top: 24px;
    >.img{
      width: 1280px;
      height: 100px;
    }
  }

  .home-replace-content-box {
    padding: 24px 0 56px;
    // display: flex;
    // align-items: flex-start;
    width: 1280px;
    margin: 0 auto;
    .home-replace-content {
      width: 100%;
      .home-replace-filter {
        background: #fff;
        padding: 24px;
        > div {
          display: flex;
          padding-bottom: 8px;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 16px;
          > span {
            width: 70px;
            margin-right: 8px;
            line-height: 24px;
            white-space: nowrap;
          }
          > div {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            height: 24px;
            overflow: hidden;
            &.active {
              height: auto;
            }
            > span {
              cursor: pointer;
              line-height: 22px;
              margin-bottom: 8px;
              height: 24px;
              border: 1px solid transparent;
              border-radius: 2px;
              padding: 0 16px;
              text-align: center;
              margin-right: 8px;
              &.active {
                border-color: #EA0B06;
                color: #EA0B06;
              }
            }
          }
          > p {
            width: 80px;
            height: 24px;
            background: #f5f5f5;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 8px;
            > i {
              color: #8c8c8c;
              margin-left: 2px;
              transition: all 0.3s;
            }
          }
          &:last-child {
            padding-bottom: 0;
            border: none;
            margin-bottom: 0;
          }
        }
      }
      .home-replace-list-num {
        font-size: 14px;
        margin: 16px 0;
        color: #8c8c8c;
      }
      .home-replace-list {
        display: flex;
        flex-wrap: wrap;
        > div {
          background: #fff;
          width: 628px;
          margin: 0 24px 24px 0;
          padding: 24px;
          transition: all 0.3s;
          display: flex;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 6px 16px 0px rgba(0, 65, 152, 0.08);
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
          // &:nth-last-child(1) {
          //   margin-bottom: 0;
          // }
          // &:nth-last-child(2) {
          //   margin-bottom: 0;
          // }
          > span {
            width: 120px;
            height: 120px;
            margin-right: 24px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > div {
            flex: 1;
            > p {
              line-height: 15px;
              font-size: 16px;
              margin-bottom: 16px;
            }
            .home-replace-list-lable {
              display: flex;
              margin-bottom: 20px;
              > p {
                height: 20px;
                background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
                border-radius: 2px;
                padding: 0 6px;
                display: flex;
                align-items: center;
                color: #fff;
                font-size: 12px;
                margin-right: 8px;
                white-space: nowrap;
                > i {
                  margin-right: 2px;
                }
              }
              > span {
                height: 20px;
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                padding: 0 12px;
                line-height: 18px;
                font-size: 12px;
                margin-right: 8px;
              }
            }
            > span {
              font-size: 12px;
              color: #8c8c8c;
              line-height: 20px;
              display: block;
              max-height: 40px;
              overflow: hidden;
            }
            .home-replace-list-factory {
              margin-top: 24px;
              padding-top: 24px;
              border-top: 1px solid #f0f0f0;
              > p {
                font-size: 12px;
                display: flex;
                line-height: 11px;
                margin-bottom: 16px;
                &:last-child {
                  margin-bottom: 0;
                }
                > span {
                  color: #8c8c8c;
                  margin-right: 20px;
                  width: 50px;
                }
              }
            }
          }
        }
      }
      .home-replace-page {
        text-align: center;
      }
    }
  }
}
</style>
<style>
.home-replace-list span img{
  max-width: 100%;
}
</style>
