<template>
  <div class="home-solution-info">
    <div class="home-solution-info-head">
      <div class="img-box" :class="{ active: isMask }">
        <v-img
          v-if="solutionInfo.solutionLogoImg"
          :attachment-id="solutionInfo.solutionLogoImg"
          :default-img="require('@/assets/img/solution/photo.png')"
        />
      </div>
      <div class="content">
        <span>{{ solutionInfo.name }}</span>
        <p>{{ solutionInfo.solSlogan }}</p>
        <a-button size="large" class="btn-red" @click="consult"
          >国产替代咨询</a-button
        >
      </div>
    </div>
    <div class="home-solution-info-tab">
      <div>
        <a
          v-if="solutionInfo.datSolutionPainpoints != ''"
          :class="{ active: solutionTabIndex == 'rol0' }"
          @click="setFloorNavMountClick('rol0')"
          >用户痛点</a
        >
        <a
          :class="{ active: solutionTabIndex == 'rol1' }"
          @click="setFloorNavMountClick('rol1')"
          >方案介绍</a
        >
        <a
          v-if="solutionInfo.datSolutionFrameWorks"
          :class="{ active: solutionTabIndex == 'rol2' }"
          @click="setFloorNavMountClick('rol2')"
          >方案架构</a
        >
        <a
          v-if="productData != ''"
          :class="{ active: solutionTabIndex == 'rol3' }"
          @click="setFloorNavMountClick('rol3')"
          >方案产品</a
        >
        <a
          v-if="solutionInfo.datSolutionIsvs != ''"
          :class="{ active: solutionTabIndex == 'rol4' }"
          @click="setFloorNavMountClick('rol4')"
          >提供商</a
        >
        <a
          :class="{ active: solutionTabIndex == 'rol5' }"
          @click="setFloorNavMountClick('rol5')"
          >方案优势</a
        >
        <a
          :class="{ active: solutionTabIndex == 'rol6' }"
          @click="setFloorNavMountClick('rol6')"
          >应用价值</a
        >
        <a
          v-if="solutionInfo.datSolutionCasess != ''"
          :class="{ active: solutionTabIndex == 'rol7' }"
          @click="setFloorNavMountClick('rol7')"
          >应用案例</a
        >
      </div>
    </div>
    <div class="home-solution-info-box1">
      <div>
        <div
          id="rol0"
          class="solution-box home-solution-info-defect"
          v-if="solutionInfo.datSolutionPainpoints != ''"
        >
          <p>用户痛点</p>
          <div>
            <div
              v-for="(item, index) in solutionInfo.datSolutionPainpoints"
              :key="item.id"
            >
              <p>
                <img alt="图片" :src="numImg[index].img" /><span>{{
                  item.name
                }}</span>
              </p>
              <span>{{ item.painpointDiscription }}</span>
            </div>
          </div>
        </div>
        <div id="rol1" class="solution-box home-solution-info-introduce">
          <p>方案介绍</p>
          <div>
            <span
              ><v-img
                :attachment-id="solutionInfo.solutionImg"
                :default-img="require('@/assets/img/solution/kctp.png')"
            /></span>
            <div>{{ solutionInfo.solutionDiscription }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="rol2" class="solution-box home-solution-jiagou-box">
      <p>方案架构</p>
      <frame-box
        v-if="solutionInfo.datSolutionFrameWorks"
        :val="solutionInfo.datSolutionFrameWorks"
      />
    </div>
    <div id="rol3" class="solution-box solution-product">
      <div v-if="productData != ''">
        <p>方案产品</p>
        <solution-product :val="productData" @product="productInfo" />
      </div>
    </div>
    <div id="rol4" class="solution-box home-solution-provider">
      <div v-if="solutionInfo.datSolutionIsvs != ''">
        <p>提供商</p>
        <provider :val="solutionInfo.datSolutionIsvs" />
      </div>
    </div>
    <div id="rol5" class="solution-box home-solution-advantage">
      <div>
        <p>方案优势</p>
        <advantage :val="solutionInfo.datSolutionAdvs" />
      </div>
    </div>
    <div id="rol6" class="solution-box home-solution-jiazhi">
      <p>应用价值</p>
      <jiazhi :val="solutionInfo.datSolutionValues" />
    </div>
    <div
      id="rol7"
      class="solution-box"
      :class="{ 'home-solution-case': solutionInfo.datSolutionCasess != '' }"
    >
      <div v-if="solutionInfo.datSolutionCasess != ''">
        <p>应用案例</p>
        <cases :val="solutionInfo.datSolutionCasess" />
      </div>
    </div>
    <!--    <div class="home-solution-footer" v-if="!isLogin">
      <div>
        <p>立即注册，即刻开启您的鸿联云之旅</p>
        <span @click="$router.push('/register')">立即体验</span>
      </div>
    </div>-->

    <sub-bottom></sub-bottom>
  </div>
</template>

<script>
import sub_bottom from "@/page/solution/components/sub_bottom";

var TIMER = null;
import frameBox from "./components/frame.vue";
import solutionProduct from "./components/solutionProduct.vue";
import provider from "./components/provider.vue";
import advantage from "./components/advantage.vue";
import jiazhi from "./components/jiazhi.vue";
import cases from "./components/case.vue";
import { getSolutionInfo } from "@/api/solution";
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
export default {
  components: {
    frameBox,
    solutionProduct,
    provider,
    advantage,
    jiazhi,
    cases,
    VImg,
    "sub-bottom": sub_bottom,
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadGetSolutionInfo();
  },
  data() {
    return {
      solutionTabIndex: "rol0",
      solutionTab: [
        {
          id: "box1",
          name: "用户痛点",
        },
        {
          id: "box2",
          name: "方案介绍",
        },
        {
          id: "box3",
          name: "方案架构",
        },
        {
          id: "box4",
          name: "方案产品",
        },
        {
          id: "box6",
          name: "提供商",
        },
        {
          id: "box7",
          name: "方案优势",
        },
        {
          id: "box5",
          name: "应用价值",
        },
        {
          id: "box8",
          name: "应用案例",
        },
      ],
      solutionInfo: "",
      numImg: [
        {
          img: require("@/assets/img/solution/1.png"),
        },
        {
          img: require("@/assets/img/solution/2.png"),
        },
        {
          img: require("@/assets/img/solution/3.png"),
        },
        {
          img: require("@/assets/img/solution/4.png"),
        },
        {
          img: require("@/assets/img/solution/5.png"),
        },
        {
          img: require("@/assets/img/solution/6.png"),
        },
        {
          img: require("@/assets/img/solution/7.png"),
        },
        {
          img: require("@/assets/img/solution/8.png"),
        },
        {
          img: require("@/assets/img/solution/9.png"),
        },
      ],
      productData: [],
      isMask: false,
    };
  },
  methods: {
    // 方案详情
    loadGetSolutionInfo() {
      getSolutionInfo(this.id).then((res) => {
        this.solutionInfo = res;
        if (res.datSolutionFrameWorks) {
          let data = [];
          res.datSolutionFrameWorks.forEach((element) => {
            if (element.datProductList) {
              data = [...data, ...element.datProductList];
            }
          });
          this.productData = data;
        }
        let createDate = new Date(res.createTime);
        let upDate = new Date("2022-07-21 00:00:00");
        createDate = createDate.getTime();
        upDate = upDate.getTime();
        if (createDate > upDate) {
          this.isMask = true;
        }
      });
    },
    // 国产替代咨询
    consult() {
      /* if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }*/
      /* this.$router.push({
        name: "userScenesAdd",
        params: {
          type: "add",
          info: "",
        },
      });*/
      window.open(`/account/userLayout/userScenes/add`, "_blank");
    },
    // 详情
    productInfo(val) {
      const routeOne = this.$router.resolve({
        path: "/replace/info",
        query: { id: val },
      });
      window.open(routeOne.href, "_blank");
    },

    setFloorNavMountClick(id) {
      this.solutionTabIndex = id;
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },

    /*// 楼层导航
    setFloorNavMountClick(index) {
      this.solutionTabIndex = index;
      var _this = this;
      let floor_item = document.getElementsByClassName("solution-box"),
        floor_offsetTop = floor_item[index].offsetTop - floor_item[0].offsetTop,
        window_scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop,
        timer = {
          step: 80,
          times: 10,
          FLOOR_OFFSETTOP: floor_offsetTop,
        };
      if (window_scrollTop > floor_offsetTop) {
        _this.setFloorScrollArrowUp(timer);
      } else if (window_scrollTop == floor_offsetTop) {
        return false;
      } else {
        _this.setFloorScrollArrowDown(timer);
      }
    },
    setFloorScrollArrowUp(timer) {
      clearInterval(TIMER);
      TIMER = setInterval(() => {
        const window_scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (window_scrollTop <= timer.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = timer.FLOOR_OFFSETTOP + 500;
          document.body.scrollTop = timer.FLOOR_OFFSETTOP + 500;
          clearInterval(TIMER);
        } else {
          document.documentElement.scrollTop = window_scrollTop - timer.step;
          document.body.scrollTop = window_scrollTop - timer.step;
        }
      }, timer.times);
    },
    setFloorScrollArrowDown(timer) {
      clearInterval(TIMER);
      TIMER = setInterval(() => {
        const window_scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (window_scrollTop >= timer.FLOOR_OFFSETTOP) {
          document.documentElement.scrollTop = timer.FLOOR_OFFSETTOP + 500;
          document.body.scrollTop = timer.FLOOR_OFFSETTOP + 500;
          clearInterval(TIMER);
        } else {
          document.documentElement.scrollTop = window_scrollTop + timer.step;
          document.body.scrollTop = window_scrollTop - timer.step;
        }
      }, timer.times);
    },*/
  },
};
</script>

<style lang="scss" scoped>
.home-solution-info {
  background: #fff;
  .home-solution-info-head {
    background: #fff;
    height: 360px;
    position: relative;
    display: flex;
    justify-content: center;
    > .img-box {
      width: 1920px;
      height: 360px;
      position: relative;
      > img {
        max-width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
      }
      &.active {
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          background: linear-gradient(
            90deg,
            #eef3f9 0%,
            #eaf1f7 54%,
            rgba(235, 241, 248, 0) 79%,
            rgba(236, 241, 249, 0) 100%
          );
        }
        > img {
          left: auto;
          width: 1037px;
        }
      }
    }

    > .content {
      position: absolute;
      z-index: 3;
      width: 1280px;
      top: 103px;
      left: 50%;
      margin-left: -640px;
      > span {
        line-height: 64px;
        font-size: 48px;
        display: block;
        margin-bottom: 13px;
      }
      > p {
        display: flex;
        align-items: center;
        line-height: 20px;
        margin-bottom: 24px;
      }
      > button {
        font-size: 14px;
        width: 160px;
      }
    }
  }
  .home-solution-info-tab {
    background: #fff;
    > div {
      width: 1280px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 8px 40px;
      > a {
        height: 56px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        line-height: 56px;
        color: #262626;
        font-size: 18px;
        &.active {
          color: #ea0b06;
          border-color: #ea0b06;
        }
      }
    }
  }
  .home-solution-info-box1 {
    background: url(../../assets/img/solution/background12.png) no-repeat;
    background-size: 100% 100%;
    padding: 50px 0;
    > div {
      width: 1280px;
      margin: 0 auto;
      .home-solution-info-defect {
        margin-bottom: 46px;
        > p {
          font-size: 30px;
          text-align: center;
          margin-bottom: 77px;
          line-height: 40px;
        }
        > div {
          display: flex;
          flex-wrap: wrap;
          > div {
            width: 50%;
            padding: 0 34px;
            margin-bottom: 80px;
            &:nth-last-child(1),
            &:nth-last-child(2) {
              margin-bottom: 0;
            }
            > p {
              position: relative;
              font-size: 18px;
              font-weight: bold;
              line-height: 24px;
              margin-bottom: 12px;
              > img {
                position: absolute;
                left: -34px;
                bottom: -6px;
              }
              > span {
                position: relative;
                z-index: 2;
              }
            }
            > span {
              display: block;
              font-size: 16px;
              line-height: 24px;
              color: #333;
              text-align: justify;
            }
          }
        }
      }
      .home-solution-info-introduce {
        > p {
          text-align: center;
          line-height: 40px;
          font-size: 30px;
          margin-bottom: 50px;
        }
        > div {
          background: url(../../assets/img/solution/bg4.png) no-repeat;
          background-size: 100% 100%;
          padding: 40px;
          display: flex;
          > span {
            width: 478px;
            height: 270px;
            margin-right: 40px;
            > img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          > div {
            flex: 1;
            padding-top: 30px;
            font-size: 16px;
            line-height: 24px;
            text-align: justify;
          }
        }
      }
    }
  }
  .home-solution-jiagou-box {
    width: 1280px;
    margin: 0 auto;
    padding: 50px 0;
    > p {
      line-height: 40px;
      font-size: 30px;
      color: #333333;
      text-align: center;
      margin-bottom: 46px;
    }
  }
  .solution-product {
    background: url(../../assets/img/solution/bg3.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 50px 0 56px;
    > div {
      width: 1280px;
      margin: 0 auto;
      > p {
        line-height: 40px;
        font-size: 30px;
        color: #fff;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  .home-solution-provider {
    width: 1280px;
    margin: 0 auto;
    > div {
      padding: 50px 0;
      > p {
        line-height: 40px;
        font-size: 30px;
        color: #333333;
        text-align: center;
        margin-bottom: 8px;
      }
    }
  }
  .home-solution-advantage {
    padding: 50px 0;
    background: #f7f8fa;
    > div {
      width: 1280px;
      margin: 0 auto;
      > p {
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  .home-solution-jiazhi {
    width: 1280px;
    margin: 0 auto;
    padding: 50px 0 106px;
    > p {
      line-height: 40px;
      font-size: 30px;
      color: #333333;
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .home-solution-case {
    padding: 50px 0;
    background: #f7f8fa;
    > div {
      width: 1280px;
      margin: 0 auto;
      > p {
        line-height: 40px;
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
  .home-solution-footer {
    background: url(../../assets/img/solution/bg6.png) no-repeat;
    background-size: 100% 100%;
    padding: 40px 0 44px;
    > div {
      width: 1280px;
      margin: 0 auto;
      > p {
        line-height: 31px;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 29px;
        text-align: center;
      }
      > span {
        display: block;
        width: 120px;
        height: 40px;
        background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        color: #fff;
      }
    }
  }
}
</style>
